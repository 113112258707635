
const Cables = () => {
    return (
        <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.9445 1V19C18.9445 19.2652 18.8391 19.5196 18.6516 19.7071C18.464 19.8946 18.2097 20 17.9445 20H1.94446C1.67924 20 1.42489 19.8946 1.23735 19.7071C1.04981 19.5196 0.944458 19.2652 0.944458 19V1C0.944458 0.734784 1.04981 0.48043 1.23735 0.292893C1.42489 0.105357 1.67924 0 1.94446 0H17.9445C18.2097 0 18.464 0.105357 18.6516 0.292893C18.8391 0.48043 18.9445 0.734784 18.9445 1ZM2.94446 14V18H16.9445V14H2.94446ZM12.9445 15H14.9445V17H12.9445V15Z" fill="#FB637E" />
        </svg>

    )
}

export default Cables; 


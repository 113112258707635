
const Acessories = () => {
    return (

        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_11_890)">
                <path d="M11.3633 2H13.0813C15.0953 2 16.1753 2.278 17.1533 2.801C18.1196 3.31249 18.9098 4.10269 19.4213 5.069C19.9443 6.047 20.2223 7.127 20.2223 9.141V14.859C20.2223 16.873 19.9443 17.953 19.4213 18.931C18.9098 19.8973 18.1196 20.6875 17.1533 21.199C16.1753 21.722 15.0953 22 13.0813 22H11.3623C9.34829 22 8.26829 21.722 7.29029 21.199C6.32398 20.6875 5.53378 19.8973 5.02229 18.931C4.50029 17.953 4.22229 16.873 4.22229 14.859V9.14C4.22229 7.126 4.50029 6.046 5.02329 5.068C5.53521 4.10192 6.32576 3.31206 7.29229 2.801C8.26929 2.278 9.34929 2 11.3633 2ZM11.2223 6V11H13.2223V6H11.2223Z" fill="#8CC8FA" />
            </g>
            <defs>
                <clipPath id="clip0_11_890">
                    <rect width="24" height="24" fill="white" transform="translate(0.22229)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Acessories


const TV = () => {
    return (

        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_11_870)">
                <path d="M15.5252 5.00005H21.1192C21.6672 5.00005 22.1112 5.44505 22.1112 6.00005V20C22.1112 20.552 21.6562 21 21.1192 21H3.10319C2.97232 20.9998 2.84279 20.9737 2.72203 20.9232C2.60127 20.8728 2.49166 20.799 2.39949 20.7061C2.30733 20.6132 2.23441 20.503 2.18494 20.3818C2.13546 20.2607 2.1104 20.1309 2.11119 20V6.00005C2.11119 5.44805 2.56619 5.00005 3.10319 5.00005H8.69719L6.16119 2.46405L7.57519 1.05005L11.5252 5.00005H12.6972L16.6472 1.05005L18.0612 2.46405L15.5252 5.00005Z" fill="#F6A973" />
            </g>
            <defs>
                <clipPath id="clip0_11_870">
                    <rect width="24" height="24" fill="white" transform="translate(0.111206)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default TV

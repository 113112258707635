
const Computers = () => {
    return (

        <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.8334 15V17H15.8334V19H5.83335V17H9.83335V15H1.82535C1.69404 14.9992 1.56417 14.9725 1.44319 14.9215C1.32221 14.8704 1.2125 14.7959 1.12035 14.7024C1.02819 14.6088 0.955402 14.498 0.906154 14.3763C0.856907 14.2546 0.832167 14.1243 0.833354 13.993V1.007C0.833354 0.451 1.28835 0 1.82535 0H19.8414C20.3894 0 20.8334 0.449 20.8334 1.007V13.993C20.8334 14.549 20.3784 15 19.8414 15H11.8334Z" fill="#D6C8FC" />
        </svg>
    )
}

export default Computers

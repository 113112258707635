const Carita = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00001 14.6667C4.31801 14.6667 1.33334 11.682 1.33334 8C1.33334 4.318 4.31801 1.33333 8.00001 1.33333C11.682 1.33333 14.6667 4.318 14.6667 8C14.6667 11.682 11.682 14.6667 8.00001 14.6667ZM4.66668 8C4.66668 8.88405 5.01787 9.7319 5.64299 10.357C6.26811 10.9821 7.11596 11.3333 8.00001 11.3333C8.88407 11.3333 9.73191 10.9821 10.357 10.357C10.9822 9.7319 11.3333 8.88405 11.3333 8H10C10 8.53043 9.7893 9.03914 9.41422 9.41421C9.03915 9.78928 8.53044 10 8.00001 10C7.46958 10 6.96087 9.78928 6.5858 9.41421C6.21072 9.03914 6.00001 8.53043 6.00001 8H4.66668Z"
        fill="#000032"
      />
    </svg>
  );
};

export default Carita;

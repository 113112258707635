
const XIcon = () => {
    return (

        <svg width="24" height="24" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.646039 0.645917C0.692485 0.599354 0.74766 0.562411 0.808405 0.537204C0.869151 0.511998 0.934272 0.499023 1.00004 0.499023C1.06581 0.499023 1.13093 0.511998 1.19167 0.537204C1.25242 0.562411 1.30759 0.599354 1.35404 0.645917L4.00004 3.29292L6.64604 0.645917C6.69253 0.599429 6.74772 0.562553 6.80846 0.537394C6.86919 0.512234 6.9343 0.499285 7.00004 0.499285C7.06578 0.499285 7.13088 0.512234 7.19162 0.537394C7.25236 0.562553 7.30755 0.599429 7.35404 0.645917C7.40053 0.692405 7.4374 0.747594 7.46256 0.808333C7.48772 0.869073 7.50067 0.934173 7.50067 0.999917C7.50067 1.06566 7.48772 1.13076 7.46256 1.1915C7.4374 1.25224 7.40053 1.30743 7.35404 1.35392L4.70704 3.99992L7.35404 6.64592C7.40053 6.6924 7.4374 6.74759 7.46256 6.80833C7.48772 6.86907 7.50067 6.93417 7.50067 6.99992C7.50067 7.06566 7.48772 7.13076 7.46256 7.1915C7.4374 7.25224 7.40053 7.30743 7.35404 7.35392C7.30755 7.40041 7.25236 7.43728 7.19162 7.46244C7.13088 7.4876 7.06578 7.50055 7.00004 7.50055C6.9343 7.50055 6.86919 7.4876 6.80846 7.46244C6.74772 7.43728 6.69253 7.40041 6.64604 7.35392L4.00004 4.70692L1.35404 7.35392C1.30755 7.40041 1.25236 7.43728 1.19162 7.46244C1.13088 7.4876 1.06578 7.50055 1.00004 7.50055C0.934295 7.50055 0.869195 7.4876 0.808455 7.46244C0.747716 7.43728 0.692527 7.40041 0.646039 7.35392C0.599551 7.30743 0.562675 7.25224 0.537516 7.1915C0.512357 7.13076 0.499407 7.06566 0.499407 6.99992C0.499407 6.93417 0.512357 6.86907 0.537516 6.80833C0.562675 6.74759 0.599551 6.6924 0.646039 6.64592L3.29304 3.99992L0.646039 1.35392C0.599476 1.30747 0.562533 1.2523 0.537326 1.19155C0.51212 1.13081 0.499146 1.06568 0.499146 0.999917C0.499146 0.93415 0.51212 0.869028 0.537326 0.808283C0.562533 0.747538 0.599476 0.692363 0.646039 0.645917Z" fill="white" />
        </svg>
    )
}

export default XIcon




const Gadgets = () => {
  return (
    <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.55554 10H5.55554C6.08598 10 6.59468 10.2107 6.96976 10.5858C7.34483 10.9609 7.55554 11.4696 7.55554 12V17C7.55554 17.5304 7.34483 18.0391 6.96976 18.4142C6.59468 18.7893 6.08598 19 5.55554 19H2.55554C2.02511 19 1.5164 18.7893 1.14133 18.4142C0.766256 18.0391 0.555542 17.5304 0.555542 17V10C0.555542 4.477 5.03254 0 10.5555 0C16.0785 0 20.5555 4.477 20.5555 10V17C20.5555 17.5304 20.3448 18.0391 19.9698 18.4142C19.5947 18.7893 19.086 19 18.5555 19H15.5555C15.0251 19 14.5164 18.7893 14.1413 18.4142C13.7663 18.0391 13.5555 17.5304 13.5555 17V12C13.5555 11.4696 13.7663 10.9609 14.1413 10.5858C14.5164 10.2107 15.0251 10 15.5555 10H18.5555C18.5555 7.87827 17.7127 5.84344 16.2124 4.34315C14.7121 2.84285 12.6773 2 10.5555 2C8.43381 2 6.39898 2.84285 4.89869 4.34315C3.3984 5.84344 2.55554 7.87827 2.55554 10Z" fill="#461E0A"/>
    </svg>
    
  )
}

export default Gadgets

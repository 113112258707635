
const Barras = () => {
    return (
        <svg width="24" height="26" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M28.25 5.625H3.75C3.6125 5.625 3.5 5.75156 3.5 5.90625V8.15625C3.5 8.31094 3.6125 8.4375 3.75 8.4375H28.25C28.3875 8.4375 28.5 8.31094 28.5 8.15625V5.90625C28.5 5.75156 28.3875 5.625 28.25 5.625ZM28.25 27.5625H3.75C3.6125 27.5625 3.5 27.6891 3.5 27.8438V30.0938C3.5 30.2484 3.6125 30.375 3.75 30.375H28.25C28.3875 30.375 28.5 30.2484 28.5 30.0938V27.8438C28.5 27.6891 28.3875 27.5625 28.25 27.5625ZM28.25 16.5938H3.75C3.6125 16.5938 3.5 16.7203 3.5 16.875V19.125C3.5 19.2797 3.6125 19.4062 3.75 19.4062H28.25C28.3875 19.4062 28.5 19.2797 28.5 19.125V16.875C28.5 16.7203 28.3875 16.5938 28.25 16.5938Z" fill="black" />
        </svg>
    )
}

export default Barras

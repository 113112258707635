const Components = () => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_11_865)">
                <path d="M14.7778 20H10.7778V22H8.77783V20H5.77783C5.51262 20 5.25826 19.8946 5.07073 19.7071C4.88319 19.5196 4.77783 19.2652 4.77783 19V16H2.77783V14H4.77783V10H2.77783V8H4.77783V5C4.77783 4.73478 4.88319 4.48043 5.07073 4.29289C5.25826 4.10536 5.51262 4 5.77783 4H8.77783V2H10.7778V4H14.7778V2H16.7778V4H19.7778C20.043 4 20.2974 4.10536 20.4849 4.29289C20.6725 4.48043 20.7778 4.73478 20.7778 5V8H22.7778V10H20.7778V14H22.7778V16H20.7778V19C20.7778 19.2652 20.6725 19.5196 20.4849 19.7071C20.2974 19.8946 20.043 20 19.7778 20H16.7778V22H14.7778V20ZM7.77783 7V11H11.7778V7H7.77783Z" fill="#439F7E" />
            </g>
            <defs>
                <clipPath id="clip0_11_865">
                    <rect width="24" height="24" fill="white" transform="translate(0.777832)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Components; 

const Router = () => {
    return (

        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_11_895)">
                <path d="M11.0001 14V11H13.0001V14H18.0001C18.2653 14 18.5196 14.1054 18.7072 14.2929C18.8947 14.4804 19.0001 14.7348 19.0001 15V21C19.0001 21.2652 18.8947 21.5196 18.7072 21.7071C18.5196 21.8946 18.2653 22 18.0001 22H6.00007C5.73485 22 5.4805 21.8946 5.29296 21.7071C5.10543 21.5196 5.00007 21.2652 5.00007 21V15C5.00007 14.7348 5.10543 14.4804 5.29296 14.2929C5.4805 14.1054 5.73485 14 6.00007 14H11.0001ZM2.51007 8.837C3.83507 4.864 7.58407 2 12.0001 2C16.4161 2 20.1661 4.864 21.4901 8.837L19.5921 9.469C19.0608 7.87573 18.0417 6.49 16.6791 5.50805C15.3165 4.5261 13.6796 3.99771 12.0001 3.99771C10.3205 3.99771 8.68359 4.5261 7.32104 5.50805C5.95848 6.49 4.93938 7.87573 4.40807 9.469L2.51107 8.837H2.51007ZM6.30607 10.102C6.70493 8.90741 7.46939 7.86853 8.49127 7.13239C9.51314 6.39625 10.7407 6.00015 12.0001 6.00015C13.2595 6.00015 14.487 6.39625 15.5089 7.13239C16.5307 7.86853 17.2952 8.90741 17.6941 10.102L15.7961 10.735C15.5302 9.93861 15.0205 9.24602 14.3393 8.75526C13.658 8.2645 12.8397 8.00043 12.0001 8.00043C11.1605 8.00043 10.3421 8.2645 9.66087 8.75526C8.97962 9.24602 8.46998 9.93861 8.20407 10.735L6.30607 10.102Z" fill="#00249C" />
            </g>
            <defs>
                <clipPath id="clip0_11_895">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Router

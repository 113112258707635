const Smartphones = () => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.16663 2H18.1666C18.4318 2 18.6862 2.10536 18.8737 2.29289C19.0613 2.48043 19.1666 2.73478 19.1666 3V21C19.1666 21.2652 19.0613 21.5196 18.8737 21.7071C18.6862 21.8946 18.4318 22 18.1666 22H6.16663C5.90141 22 5.64706 21.8946 5.45952 21.7071C5.27198 21.5196 5.16663 21.2652 5.16663 21V3C5.16663 2.73478 5.27198 2.48043 5.45952 2.29289C5.64706 2.10536 5.90141 2 6.16663 2ZM12.1666 17C11.9014 17 11.6471 17.1054 11.4595 17.2929C11.272 17.4804 11.1666 17.7348 11.1666 18C11.1666 18.2652 11.272 18.5196 11.4595 18.7071C11.6471 18.8946 11.9014 19 12.1666 19C12.4318 19 12.6862 18.8946 12.8737 18.7071C13.0613 18.5196 13.1666 18.2652 13.1666 18C13.1666 17.7348 13.0613 17.4804 12.8737 17.2929C12.6862 17.1054 12.4318 17 12.1666 17Z" fill="#FF9A9D" />
        </svg>

    )


}

export default Smartphones; 

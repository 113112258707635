
 const CartIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_9_12708)">
                <path d="M4 6.41408L0.757004 3.17208L2.172 1.75708L5.414 5.00008H20.656C20.8119 5.00007 20.9656 5.03649 21.1049 5.10645C21.2442 5.1764 21.3652 5.27794 21.4582 5.40298C21.5513 5.52801 21.6139 5.67306 21.6409 5.82657C21.668 5.98007 21.6587 6.13777 21.614 6.28708L19.214 14.2871C19.1523 14.4932 19.0257 14.6738 18.8531 14.8023C18.6805 14.9307 18.4711 15.0001 18.256 15.0001H6V17.0001H17V19.0001H5C4.73479 19.0001 4.48043 18.8947 4.2929 18.7072C4.10536 18.5197 4 18.2653 4 18.0001V6.41408ZM6 7.00008V13.0001H17.512L19.312 7.00008H6ZM5.5 23.0001C5.10218 23.0001 4.72065 22.842 4.43934 22.5607C4.15804 22.2794 4 21.8979 4 21.5001C4 21.1023 4.15804 20.7207 4.43934 20.4394C4.72065 20.1581 5.10218 20.0001 5.5 20.0001C5.89783 20.0001 6.27936 20.1581 6.56066 20.4394C6.84197 20.7207 7 21.1023 7 21.5001C7 21.8979 6.84197 22.2794 6.56066 22.5607C6.27936 22.842 5.89783 23.0001 5.5 23.0001ZM17.5 23.0001C17.1022 23.0001 16.7206 22.842 16.4393 22.5607C16.158 22.2794 16 21.8979 16 21.5001C16 21.1023 16.158 20.7207 16.4393 20.4394C16.7206 20.1581 17.1022 20.0001 17.5 20.0001C17.8978 20.0001 18.2794 20.1581 18.5607 20.4394C18.842 20.7207 19 21.1023 19 21.5001C19 21.8979 18.842 22.2794 18.5607 22.5607C18.2794 22.842 17.8978 23.0001 17.5 23.0001Z" fill="#09121F" />
            </g>
            <defs>
                <clipPath id="clip0_9_12708">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
export default CartIcon
